import { atom, selector, selectorFamily, AtomEffect, useSetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import _ from 'underscore';

/* Persistance Code */

const { persistAtom } = recoilPersist();

const ssrCompletedState = atom({
    key: 'SsrCompleted',
    default: false,
});

export const useSsrComplectedState = () => {
    const setSsrCompleted = useSetRecoilState(ssrCompletedState);
    return () => setSsrCompleted(true);
};

export const persistAtomEffect = (param) => {
    param.getPromise(ssrCompletedState).then(() => persistAtom(param));
};

/* Cart Selected Products Atom */

export const cartProductState = atom({
    key: 'cartProductState',
    default: [],
    effects_UNSTABLE: [persistAtomEffect],
});

export const countByIdState = selector({
    key: 'countByIdState',
    get: ({ get }) => {
        const products = get(cartProductState);
        const count = _.countBy(products, (product) => product.id);
        return count;
    },
});

export const countByIdAndSideState = selector({
    key: 'countByIdAndSideState',
    get: ({ get }) => {
        const products = get(cartProductState);

        const hashMealAndSide = (product) => {
            const mealId = product.id;
            const sideId = product?.side?._id;
            return mealId + sideId;
        };

        const count = _.countBy(products, (product) => hashMealAndSide(product));
        return count;
    },
});

export const countByTypeState = selector({
    key: 'countByTypeState',
    get: ({ get }) => {
        const products = get(cartProductState);
        const count = _.countBy(products, (product) => product.type);
        return count;
    },
});

export const countByDayState = selector({
    key: 'countByDayState',
    get: ({ get }) => {
        const products = get(cartProductState);
        const count = _.countBy(products, (product) => product.day);
        return count;
    },
});

export const cartPriceState = selectorFamily({
    key: 'cartPriceState',
    get:
        (company) =>
        ({ get }) => {
            if (!company) return 0;
            const productPrices = company.products;
            const countByType = get(countByTypeState);
            const cartChoices = get(cartChoiceState);
            const dayCount = cartChoices?.delivery?.value?.days?.length;

            const getTypePrice = (type) => {
                let price = 0;
                const quantity = countByType[type];
                const typePrices = productPrices?.[type]?.pricing;

                if (!typePrices) return 0;
                Object.keys(typePrices).forEach((bracket) => {
                    if (quantity >= bracket) price = typePrices[bracket];
                });

                return price * quantity;
            };

            let price = Object.keys(countByType).reduce(
                (acc, type) => (acc += getTypePrice(type)),
                0
            );

            const getShippingPrice = () => {
                const freeOver = company?.settings?.delivery?.freeOver;
                if (price > freeOver) return 0;
                const numberOfShippingDays = dayCount;
                const shippingPrice = productPrices.shipping.pricing[0];
                return shippingPrice * numberOfShippingDays;
            };

            const priceWithShipping = price + getShippingPrice();
            const priceWithoutShipping = price;

            return { priceWithShipping, priceWithoutShipping };
        },
});

export const sortedProductsState = selector({
    key: 'sortedProductsState',
    get: ({ get }) => {
        const cartProducts = get(cartProductState);

        const groupedByDay = _.groupBy(cartProducts, 'day');
        const groupedByDayAndType = {};

        Object.keys(groupedByDay).forEach((day) => {
            groupedByDayAndType[day] = _.groupBy(groupedByDay[day], 'type');
        });

        return groupedByDayAndType;
    },
});

export const stripeProductsState = selectorFamily({
    key: 'stripeProductsState',
    get:
        (company) =>
        ({ get }) => {
            if (!company) return [];
            const products = company.products;
            const countByType = get(countByTypeState);
            const cartChoices = get(cartChoiceState);
            const dayCount = cartChoices?.delivery?.value?.days?.length;

            const cartProducts = [];

            Object.keys(countByType).forEach((type) => {
                const id = products[type].priceId;
                const quantity = countByType[type];
                const price = 0;
                Object.keys(products[type].pricing).forEach((bracket) => {
                    if (quantity >= bracket) {
                        price = products[type].pricing[bracket];
                    }
                });
                if (quantity > 0) {
                    cartProducts.push({ id, type, quantity, price });
                }
            });

            cartProducts.push({
                id: products.shipping.priceId,
                type: 'shipping',
                quantity: dayCount,
                price: products.shipping.pricing[0],
            });

            return cartProducts;
        },
});

export const cartCaloriesState = selectorFamily({
    key: 'cartCaloriesState',
    get:
        (company) =>
        ({ get }) => {
            if (!company) return 0;
            const productPrices = company.products;
            const choices = get(cartChoiceState);

            const daysOfFood = choices?.daysOfFood?.value?.value;
            const mealsPerDay = choices?.mealsPerDay?.value?.value;

            const choicesWeCount = ['breakfast', 'main', 'snack', 'sauce'];

            const filteredChoices = Object.keys(choices).filter((choice) => {
                if (choicesWeCount.includes(choice)) return true;
            });

            filteredChoices.push('main');

            const getTypeCalories = (type) => {
                let quantity = choices?.[type]?.value?.value;
                if (type === 'main') quantity = mealsPerDay;

                console.log('🚀 ~ file: cart.js ~ line 195 ~ quantity', quantity);

                const typeCalories = productPrices[type]?.calories;
                console.log('🚀 ~ file: cart.js ~ line 198 ~ typeCalories', typeCalories);

                return typeCalories * quantity * daysOfFood;
            };

            let calories = filteredChoices.reduce((acc, type) => (acc += getTypeCalories(type)), 0);

            return calories / daysOfFood;
        },
});

/* Cart Choices Atoms */

export const cartChoiceState = atom({
    key: 'cartChoiceState',
    default: {},
    effects_UNSTABLE: [persistAtomEffect],
});

export const choicePriceState = selectorFamily({
    key: 'choicePriceState',
    get:
        (company) =>
        ({ get }) => {
            if (!company) return 0;
            const productPrices = company.products;
            const choices = get(cartChoiceState);

            const daysOfFood = choices?.daysOfFood?.value?.value;
            const mealsPerDay = choices?.mealsPerDay?.value?.value;

            const choicesWeCount = ['breakfast', 'main', 'snack', 'sauce'];

            const filteredChoices = Object.keys(choices).filter((choice) => {
                if (choicesWeCount.includes(choice)) return true;
            });

            filteredChoices.push('main');

            const getTypePrice = (type) => {
                if (type === 'main') {
                    const mealCount = daysOfFood * mealsPerDay;
                    const typePrices = productPrices[type]?.pricing;

                    let price = 0;

                    Object.keys(typePrices).forEach((bracket) => {
                        if (mealCount >= bracket) price = typePrices[bracket];
                    });

                    return price * mealCount;
                }

                if (type === 'sauce') {
                    const quantity = choices?.[type]?.value?.value;
                    const typePrices = productPrices[type]?.pricing;

                    let price = typePrices[0];

                    return price * quantity;
                }

                let price = 0;
                const quantity = choices?.[type]?.value?.value;
                const typePrices = productPrices[type]?.pricing;

                Object.keys(typePrices).forEach((bracket) => {
                    if (quantity >= bracket) price = typePrices[bracket];
                });

                return price * quantity * daysOfFood;
            };

            let price = filteredChoices.reduce((acc, type) => (acc += getTypePrice(type)), 0);

            const getShippingPrice = () => {
                const numberOfShippingDays = choices?.delivery?.value?.days
                    ? choices.delivery.value.days.length
                    : 2;
                const shippingPrice = productPrices.shipping.pricing[0];
                return shippingPrice * numberOfShippingDays;
            };

            const priceWithShipping = price + getShippingPrice();
            const priceWithoutShipping = price;

            return { priceWithShipping, priceWithoutShipping };
        },
});
