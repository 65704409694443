import React, { useEffect } from 'react';

import { useSsrComplectedState } from '@/atoms/cart';
import { useRecoilState } from 'recoil';

export default function TrackSSR(props) {
    const { children } = props;

    const setSsrCompleted = useSsrComplectedState();
    useEffect(setSsrCompleted, [setSsrCompleted]);

    return <div className="">{children}</div>;
}
