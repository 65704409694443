import '../styles/globals.css';
import React from 'react';
import NextApp from 'next/app';
import * as Sentry from '@sentry/react';
import { RecoilRoot } from 'recoil';
import TrackSSR from '@/components/page/TrackSSR';

class App extends NextApp {
    static async getInitialProps({ Component, ctx }) {
        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        return {
            pageProps,
        };
    }

    render() {
        const { Component, pageProps } = this.props;

        if (process.env.NEXT_PUBLIC_ENV !== 'local') {
            Sentry.init({
                dsn: 'https://e70c4255c94e4a248d7ff2412678d8ed@o1018978.ingest.sentry.io/6169025',

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
            });
        }

        return (
            <RecoilRoot>
                <TrackSSR>
                    <Component {...pageProps} />
                </TrackSSR>
            </RecoilRoot>
        );
    }
}

export default App;
